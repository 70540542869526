import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
        _authenticated: false,
        _userObject: null,
        _currentLocation: null
    },
    mutations: {
        change (state, value) {
            state._authenticated = value
        },

        userObject (state, value) {
            state._userObject = value
        },

        currentLocation (state, value) {
            state._currentLocation = value;
        }
    },
    getters: {
        isAuthenticated: state => {
            if (state._authenticated === 'true' || state._authenticated === true) {
                return true
            }

            return false
        },

        getUserObject: state => {
            return state._userObject;
        },

        getCurrentLocation: state => {
            return state._currentLocation
        },
    }
});