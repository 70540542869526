<template>
    <b-overlay :show="this.showLoading" rounded="sm">
        <div class="content register">
            <div class="row">
                <div class="col-12">
                    <h1 class="text-left">Neuen Account erstellen</h1>
                    <form @submit="checkForm" @submit.prevent>
                        <b-alert :show="isError" fade variant="danger" class="text-left">
                            <div v-for="error in errors" v-bind:key="error.id">{{ error }}</div>
                        </b-alert>

                        <b-alert :show="isSuccess" fade variant="success" class="text-left">
                            <div>Ihre Registrierung war erfolgreich!</div>
                        </b-alert>

                        <div class="form-row">
                            <div class="form-group col-md-12 text-left">
                                <select class="select" v-model="salutation" required>
                                    <option value="" disabled>Anrede wählen</option>
                                    <option value="-">Keine Angabe</option>
                                    <option value="Herr">Herr</option>
                                    <option value="Frau">Frau</option>
                                </select>
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <input type="text" class="form-control" required placeholder="Vornamen eingeben" v-model="firstname" />
                            </div>
                            <div class="form-group col-md-6">
                                <input type="text" class="form-control" required placeholder="Nachnamen eingeben" v-model="lastname">
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <input type="email" class="form-control" required placeholder="E-Mail Adresse eingeben"
                                       v-model="email" v-bind:class="{ 'is-invalid': mailError }" @blur="checkMail" />
                            </div>
                            <div class="form-group col-md-6">
                                <input type="text" class="form-control" required placeholder="Benutzernamen eingeben"
                                       v-model="username" v-bind:class="{ 'is-invalid': userError }" @blur="checkUserName" />
                            </div>
                            <div class="form-group col-md-6">
                                <input type="password" class="form-control" minlength="8" required placeholder="Passwort eingeben" aria-describedby="passwordHelp"  v-model="password">
                                <small id="passwordHelp" class="form-text text-left">Mindestlänge 8 Zeichen</small>
                            </div>
                        </div>

                        <div class="form-group">
                            <input type="tel" class="form-control" required placeholder="Telefonnummer eingeben" v-model="phone">
                        </div>
                        <div class="form-group">
                            <input type="text" class="form-control" required placeholder="Straße und Hausnummer eingeben" v-model="street">
                        </div>
                        <div class="form-row">
                            <div class="form-group col-6">
                                <input type="text" class="form-control" placeholder="PLZ eingeben" required v-model="zip">
                            </div>
                            <div class="form-group col-6">
                                <input type="text" class="form-control" placeholder="Ort eingeben" required v-model="city">
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="text-left hint">
                                <strong>Datenschutz</strong><br />
                                Ich habe die <a href="/#/datenschutz">Datenschutzbestimmungen</a>  zur Kenntnis genommen.
                            </div>
                        </div>
                        <button type="submit" class="btn btn-primary btn-block">jetzt registrieren</button>
                    </form>
                </div>
            </div>
        </div>
    </b-overlay>
</template>

<script>
    export default {
        name: "Register",
        data() {
            return {
                isError: false,
                isSuccess: false,
                userError: false,
                mailError: false,
                errors: [],
                showLogo: true,
                salutation: '',
                firstname: '',
                lastname: '',
                email: '',
                username: '',
                password: '',
                street: '',
                zip: '',
                city: '',
                phone: '',
                showLoading: true,
            }
        },
        created() {
            this.showLoading = false;
        },
        methods:{
            checkForm: function (e) {
                this.errors = [];
                this.isError = false;

                if (!this.salutation) {
                    this.errors.push(this.$msg.error["empty-salutation"]);
                }

                if (!this.firstname) {
                    this.errors.push(this.$msg.error["empty-firstname"]);
                }

                if (!this.lastname) {
                    this.errors.push(this.$msg.error["empty-lastname"]);
                }

                if (!this.email) {
                    this.errors.push(this.$msg.error["empty-mail"]);

                    this.checkMail();
                }

                if (!this.username) {
                    this.errors.push(this.$msg.error["empty-username"]);

                    this.checkUserName();
                }

                if (!this.password) {
                    this.errors.push(this.$msg.error["empty-password"]);
                }

                if (!this.street) {
                    this.errors.push(this.$msg.error["empty-street"]);
                }

                if (!this.zip) {
                    this.errors.push(this.$msg.error["empty-zip"]);
                }

                if (!this.city) {
                    this.errors.push(this.$msg.error["empty-city"]);
                }

                if (this.errors.length === 0) {
                    this.register();
                } else {
                    this.isError = true;
                }

                e.preventDefault();
            },

            register: function() {
                this.showLoading = true;

                fetch(this.$url + '/api/account/register', {
                    method: 'POST',
                    cache: 'no-cache',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        'salutation': this.salutation,
                        'firstname': this.firstname,
                        'lastname': this.lastname,
                        'email': this.email,
                        'username': this.username,
                        'password_plain': this.password,
                        'street': this.street,
                        'zip': this.zip,
                        'city': this.city,
                        'phone': this.phone,
                    })
                })
                .then (response => response.json())
                .then(data => {
                    this.isError = false;
                    this.isSuccess = false;

                    if (data.success === true) {
                        this.isError = false;
                        this.isSuccess = true;
                    } else {
                        this.isError = true;
                        this.isSuccess = false;

                        if (typeof(this.$msg.error[data.error]) != 'undefined')
                            this.errors.push(this.$msg.error[data.error]);
                        else
                            this.errors.push(this.$msg.error.unknown);
                    }

                    this.showLoading = false;
                })
                .catch(data => {
                    this.isError = true;
                    this.errors.push(this.$msg.error["unknown"]);
                    console.error(data);

                    this.showLoading = false;
                })
            },

            checkMail: function () {
                fetch(this.$url + '/api/account/check', {
                    method: 'POST',
                    cache: 'no-cache',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        'filter': 'email',
                        'check_field': this.email,
                    })
                })
                .then (response => response.json())
                .then(data => {
                    this.mailError = false;

                    if (!this.userError)
                        this.isError = false;

                    if (data.success === true) {
                        this.isError = true;
                        this.mailError = true;
                        this.errors.push(this.$msg.error["existing-mail"])
                    } else {
                        this.isError = true;
                        this.errors.push(this.$msg.error["unknown"])
                    }
                })
                .catch(data => {
                    this.isError = true;
                    this.errors.push(this.$msg.error["unknown"]);
                    console.error(data);
                })
            },

            checkUserName: function () {
                fetch(this.$url + '/api/account/check', {
                    method: 'POST',
                    cache: 'no-cache',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        'filter': 'username',
                        'check_field': this.username,
                    })
                })
                .then (response => response.json())
                .then(data => {
                    this.userError = false;

                    if (!this.mailError)
                        this.isError = false;

                    if (data.success === true) {
                        this.userError = true;
                        this.isError = true;
                        this.errors.push(this.$msg.error["existing-user"]);
                    } else {
                        this.isError = true;
                        this.errors.push(this.$msg.error["unknown"])
                    }
                })
                .catch(data => {
                    this.isError = true;
                    this.errors.push(this.$msg.error["unknown"]);
                    console.error(data);
                })
            },
        }
    }
</script>
