<template>
  <div id="app" :class="this.$componentName">
    <div class="container">
      <header>
        <div class="row">
          <div class="col-12">
            <b-navbar toggleable>
              <b-navbar-brand to="/">
                <img alt="bring2 Logo" class="logo" src="./assets/bring2_logo_white.png" />
              </b-navbar-brand>

              <b-navbar-toggle target="navbar-toggle-collapse">
                <template v-slot:default="{ expanded }">
                  <b-icon v-if="expanded" icon="x"></b-icon>
                  <b-icon v-else icon="list"></b-icon>
                </template>
              </b-navbar-toggle>

              <b-collapse id="navbar-toggle-collapse" is-nav ref="menu">
                <b-navbar-nav class="ml-auto">
                  <b-nav-item to="/uebersicht">Übersicht</b-nav-item>
                  <b-nav-item to="/routen/uebersicht">Meine Routen</b-nav-item>
                  <b-nav-item to="/routen">Routen zum mitfahren</b-nav-item>
                  <b-nav-item to="/routen/anlegen">Eine neue Route erstellen</b-nav-item>
                  <b-nav-item to="/datenschutz">Datenschutz</b-nav-item>
                  <b-nav-item to="/impressum">Impressum</b-nav-item>
                </b-navbar-nav>
              </b-collapse>
            </b-navbar>
          </div>
        </div>
      </header>

      <router-view />

      <footer>
        <div class="powered-by">
            <div class="text">powered by</div>
            <img src="./assets/holzwinkel-logo.png" class="logo" />
        </div>
      </footer>
    </div>
    <div class="overlay" v-bind:class="{ 'd-block': this.showMenu }"></div>
  </div>
</template>

<script>
  import 'bootstrap/dist/css/bootstrap.css'
  import 'bootstrap-vue/dist/bootstrap-vue.css'

  // TODO: back btn
  // TODO: to top

  export default {
    name: 'App',

    data() {
      return {
        gettingLocation: false,
        errorStr: null,
        overlay: null,
        showMenu: false,
      }
    },

    mounted() {
      var me = this;

      this.$refs.menu.$on('show', function () {
        me.showMenu = true;
        document.querySelector('body').style.overflow = 'hidden';
      });

      this.$refs.menu.$on('hidden', function () {
        me.showMenu = false;
        document.querySelector('body').style.overflow = 'auto';
      });
    },

    created() {
      this.overlay = document.querySelector('.overlay');
      this.getCurrentLocation();
    },

    methods: {
      getCurrentLocation() {
        //do we support geolocation
        if(!("geolocation" in navigator)) {
          this.errorStr = 'Geolocation is not available.';
          return;
        }

        this.gettingLocation = true;
        // get position
        navigator.geolocation.getCurrentPosition(pos => {
          this.gettingLocation = false;
          this.$store.commit('currentLocation', pos);
        }, err => {
          this.gettingLocation = false;
          this.errorStr = err.message;
        })
      }
    }
  }
</script>