export default {
    colors: [ 'red', 'blue', 'green', 'yellow', 'orange', 'black', 'purple' ],

    setDirections(directionsService, map, route, index, me, suppressMarkers = true) {
        var rendererOptions = {
            // preserveViewport: true,
            suppressMarkers: suppressMarkers,
            routeIndex: index,
            polylineOptions: {
                strokeColor: this.colors[index]
            }
        };
        var items = [];

        me.directionsDisplay = new window.google.maps.DirectionsRenderer(rendererOptions);
        me.directionsDisplay.setMap(map);

        var waypoints = [];
        if(typeof(route.routeStopLocations) !== 'undefined') {
            for (var i = 0; i < route.routeStopLocations.length; i++) {
                waypoints[i] = {
                    location: route.routeStopLocations[i].location,
                    stopover: true
                }
            }
        }

        directionsService.route({
            origin: route.startLocation,
            waypoints: waypoints,
            destination: route.stopLocation,
            travelMode: 'DRIVING'
        }, function (response, status) {
            if (status === 'OK') {
                var legs = response.routes[0].legs;
                var startAddress = legs[0].start_address.split(',');
                var endAddress = legs[legs.length-1].end_address.split(',');
                var stopLocations = [];

                var c = 0;
                for(var stopLocation of route.routeStopLocations) {
                    var stopAddress = legs[c+1].start_address.split(',');

                    var stopDateTime = new Date(stopLocation.stopDateTime.slice(0, 19));
                    stopLocations.push({
                        id: stopLocation.id,
                        startTime: stopLocation.stopTime,
                        startDateTime: stopDateTime.getHours() + ':' + stopDateTime.getMinutes(),
                        street: stopAddress[0],
                        city: stopAddress[1],
                        address: stopAddress[0]+ ', ' +stopAddress[1],
                        coords: stopLocation.location,
                        lat: stopLocation.location.lat,
                        lng: stopLocation.location.lng,
                    });

                    c++;
                }

                // 2020-12-11T08:15:00
                var item = {
                    id: route.id,
                    index: (items.length +1),
                    distance: route.distance,
                    duration: route.duration,
                    startTime: route.startTime,
                    startDateTime: new Date(route.startDateTime.slice(0, 19)).toISOString(),
                    user: route.user,
                    startLocation: route.startLocation,
                    stopLocation: route.stopLocation,
                    origin: {
                        street: startAddress[0],
                        city: startAddress[1]
                    },
                    destination: {
                        street: endAddress[0],
                        city: endAddress[1]
                    },
                    stopLocations: stopLocations,
                    selected: '',
                    error: false,
                    errorMessage: '',
                    booked: false,
                };

                /*
                if (me.isEditing) {
                    me.$refs.startAddressRef.$el.value = item.origin.street + ", " + item.origin.city;

                    if (me.$refs.stopAddressRef)
                        me.$refs.stopAddressRef.$el.value = item.destination.street + ", " + item.destination.city;
                }
                 */

                me.setItem(item);
                items.push(item);

                me.directionsDisplay.setDirections(response);
            } else {
                console.error('Directions request failed due to ' + status)
            }
        });
    },
}