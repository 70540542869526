import Vue from 'vue'
import VueRouter from 'vue-router'
import { store } from './store/store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import App from './App.vue'
import Home from "@/components/Home";
import Overview from "@/components/Overview";
import Routes from "@/components/Routes";
import CreateRoutes from "@/components/CreateRoutes";
import * as VueGoogleMaps from 'vue2-google-maps'
import Register from "@/components/Register";
import Privacy from "@/components/Privacy";
import Imprint from "@/components/Imprint";
import BookedRoute from "@/components/BookedRoute";
import LostPassword from "@/components/LostPassword";
import RoutesOverview from "@/components/RoutesOverview";
import Vote from "@/components/Vote";
import { Datetime } from '@/modules/vue-datetime/src';
import '@/modules/vue-datetime/dist/vue-datetime.css';

require('./assets/css/main.css');

// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use(store);
Vue.use(VueRouter);
Vue.use(Datetime);

Vue.component('datetime', Datetime);

const routes = [
    { path: '/', component: Home, props: true },
    { path: '/uebersicht', name: 'routeoverview', component: Overview, meta: { requiresAuth: true } },
    { path: '/routen', name: 'routen', component: Routes, meta: { requiresAuth: true } },
    { path: '/routen/anlegen', component: CreateRoutes, meta: { requiresAuth: true } },
    { path: '/routen/uebersicht', component: RoutesOverview, meta: { requiresAuth: true } },
    { path: '/routen/bearbeiten/:routeId', name: 'editroute', component: CreateRoutes, meta: { requiresAuth: true } },
    { path: '/routen/uebersicht/:routeId', name: 'bookedroute', component: BookedRoute, meta: { requiresAuth: true } },
    { path: '/routen/bewerten/:type/:routeId', name: 'voteroute', component: Vote, meta: { requiresAuth: true } },
    { path: '/register', component: Register, meta: { requiresAuth: false } },
    { path: '/lost-password', component: LostPassword, meta: { requiresAuth: false } },
    { path: '/lost-password/:token', component: LostPassword, meta: { requiresAuth: false } },
    { path: '/datenschutz', component: Privacy, meta: { requiresAuth: false } },
    { path: '/impressum', component: Imprint, meta: { requiresAuth: false } },
    { path: "*", redirect: "/" }
]

const router = new VueRouter({
    routes: routes
});

Vue.prototype.$componentName = 'home';
// Vue.prototype.$url = 'https://bring2.yaparis.de';
Vue.prototype.$url = 'https://app.bring2.de';
// Vue.prototype.$url = '';
Vue.prototype.$msg = {
    'error': {
        'expires-token': 'Der Token ist abgelaufen, bitte versuche es erneut!',
        'unknown': 'Es ist ein Fehler aufgetreten, bitte versuche es später erneut.',
        'empty-user': 'Es ist ein Fehler aufgetreten, bitte versuche es später erneut.',
        'recover-wrong-email': 'Die eingegebene E-Mail Adresse ist falsch, bitte überprüfe die Schreibweise deiner E-Mail Adresse',
        'wrong-mail': 'Die eingegebene E-Mail Adresse ist falsch, bitte überprüfe die Schreibweise deiner E-Mail Adresse',
        'wrong-token': 'Der Token ist abgelaufen, bitte versuche es erneut!',
        'no-password': 'Bitte geb dein Kennwort ein',
        'no-double-password': 'Bitte geb dein Kennwort erneut ein',
        'short-password': 'Das eingegebene Passwort ist zu kurz, bitte wähle ein Kennwort mit min. 8 Zeichen ein.',
        'short-double-password': 'Das eingegebene wiederholte Passwort ist zu kurz, bitte wähle ein Kennwort mit min. 8 Zeichen ein.',
        'compare-password': 'Deine beiden Kennwörter stimmen nicht überein, überprüfe bitte deine Kennwörter',
        'route-not-found': 'Die Route konnte nicht gefunden werden, bitte versuche es erneut!',
        'reset-password-already-send': 'Es wurde bereits ein Passwort vergessen Link an dich gesendet,',
        'no-street': 'Bitte geb deine Straße ein',
        'no-street-number': 'Bitte geb deine Hausnummer ein',
        'no-city': 'Bitte geb deinen Ort ein',
        'no-zip': 'Bitte geb deine Postleitzahl ein',
        'empty-start-location': 'Der Start Ort darf nicht leer sein',
        'empty-stop-location': 'Der Ankuftsort darf nicht leer sein',
        'empty-starttime': 'Bitte wähle eine Zeit für den Start Ort',
        'empty-stoptime': 'Bitte wähle eine Zeit für den Zustiegsort',
        'no-active-route': 'Die angegebene Route ist nicht mehr ativ, bitte wähle eine andere Route.',
        'no-data': 'Es ist ein Fehler aufgetreten, bitte versuche es später erneut.',
        'empty-mail': 'Deine E-Mail Adresse darf nicht leer sein',
        'existing-mail': 'Diese E-Mail Adresse existiert bereits',
        'empty-username': 'Dein Benutezrname darf nicht leer sein',
        'existing-user': 'Dieser Benutzername existiert bereits',
        'empty-password': 'Dein Passwort darf nicht leer sein',
        'empty-salutation': 'Deine Anrede darf nicht leer sein',
        'empty-firstname': 'Dein Vorname darf nicht leer sein',
        'empty-lastname': 'Dein Nachname darf nicht leer sein',
        'empty-street': 'Deine Straße darf nicht leer sein',
        'empty-zip': 'Deine Postleitzahl darf nicht leer sein',
        'empty-city': 'Deine Stadt/Ort darf nicht leer sein',
        'empty-phone': 'Deine Telefonnummer darf nicht leer sein',
    },
    'success': {
        'password-reset': 'Dein Passwort wurde erfolgreich zurück gesetzt, du kannst dich jetzt mit deinem neuen Kennwort anmelden.',
        'recover-token': 'Wenn deine E-Mail Adresse korrekt war, erhälst du eine E-Mail für die weiteren Schritte.',
    }
};
Vue.prototype.$colors = [
    'red', 'blue', 'green', 'yellowgreen', 'orange', 'black', 'purple', 'blueviolet', 'brown', 'cadetblue',
];

// check auth
var isAuthenticated = JSON.parse(document.body.attributes["data-is-authenticated"].value);
store.commit('change', isAuthenticated);

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (to.path === '/') {
            Vue.prototype.$componentName = 'home';
            document.body.classList.add('home');
        } else {
            Vue.prototype.$componentName = '';
            document.body.classList.remove('home')
        }

        if (store.getters.isAuthenticated !== false) {
            next()
        } else {
            Vue.prototype.$componentName = 'home';
            document.body.classList.add('home');

            next({
                path: '/',
                // query: { redirect: to.fullPath }
            })
        }
    } else {
        if (to.path === '/') {
            Vue.prototype.$componentName = 'home';
            document.body.classList.add('home');
        } else {
            Vue.prototype.$componentName = '';
            document.body.classList.remove('home')
        }

        next()
    }
});

Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyCYx6XRQ85puRrO7zPAl5RhEwf5Y--MH7w',
        libraries: 'places', // This is required if you use the Autocomplete plugin
        // OR: libraries: 'places,drawing'
        // OR: libraries: 'places,drawing,visualization'
        // (as you require)

        //// If you want to set the version, you can do so:
        // v: '3.26',
    },

    //// If you intend to programmatically custom event listener code
    //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
    //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
    //// you might need to turn this on.
    // autobindAllEvents: false,

    //// If you want to manually install components, e.g.
    //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
    //// Vue.component('GmapMarker', GmapMarker)
    //// then set installComponents to 'false'.
    //// If you want to automatically install all the components this property must be set to 'true':
    installComponents: true
})

Vue.config.productionTip = false;


new Vue({
    render: h => h(App),
    router,
    store,
}).$mount('#app');
