import { render, staticRenderFns } from "./ResultBox.vue?vue&type=template&id=02b17dbc&scoped=true&"
import script from "./ResultBox.vue?vue&type=script&lang=js&"
export * from "./ResultBox.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02b17dbc",
  null
  
)

export default component.exports