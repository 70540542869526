<template>
  <div class="content home">
      <div class="logo-container">
          <img src="../assets/bring2_logo_white.png" alt="bring2 Logo" class="logo"/>

          <h1>Die Mitfahrzentrale für die Region<br />Holzwinkel &amp; Altenmünster.</h1>
      </div>
      <div class="login">
          <form method="post" class="form-group" v-on:submit.prevent="sendLogin" id="loginForm">
              <b-alert :show="isError"
                       dismissible
                       variant="danger">{{ errorMessage }}</b-alert>

              <input type="text" class="form-control" placeholder="Name" aria-label="Username" aria-describedby="basic-addon1" v-model="username" />
              <input type="password" class="form-control" placeholder="Passwort" aria-label="Password" aria-describedby="basic-addon1" v-model="password">
              <input type="hidden" name="_csrf_token" v-model="csrf_token" />

              <button type="submit" class="btn btn-primary btn-lg btn-block">Login</button>
          </form>
      </div>
      <div class="password-recovery">
          <a href="/#/lost-password">
              Passwort vergessen?
          </a>
      </div>
      <div class="register">
          <a href="/#/register">
              <img src="../assets/plus-icon.png" alt="register" />
              <span class="text">Neuen Account erstellen</span>
          </a>
      </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  props: [ 'csrf_token', 'last_username' ],
  data () {
    return {
      username: '',
      password: '',
      userId: 0,
      isError: false,
      errorMessage: '',
    }
  },
  created () {
    if (this.$props.last_username !== undefined) {
      this.username = this.$props.last_username;
    }

    if (this.$store.getters.isAuthenticated === true) {
      this.$router.push('/uebersicht');
    }
  },
  methods: {
    sendLogin () {
      fetch(this.$url + '/api/account/login', {
        method: 'POST',
        cache: 'no-cache',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          'username': this.username,
          'password': this.password,
          '_csrf_token': this.$props.csrf_token
        })
      })
      .then (response => response.json())
      .then(data => {
        if (data.id > 0 && data.username !== '' && data.username === this.username) {
          this.$store.commit('change', true);
          this.$store.commit('userObject', data);
          this.isError = false;

          this.$router.push('/uebersicht');
        } else {
          this.isError = true;
          if (data.error === 'Invalid credentials.') {
              this.errorMessage = 'Login nicht möglich, Benutzername oder Kenntwort ist falsch!';
          } else {
              this.errorMessage = data.error;
          }
        }
      })
      .catch(data => {
        this.isError = true;
        this.errorMessage = ( data.error !== '' ? data.error : 'Es besteht keine Internetverbindung');
      })
    }
  }
}
</script>