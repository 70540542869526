<template>
    <div class="box" v-bind:class="{ booked: item.booked && !isDetail }">
        <div class="row">
            <div class="col-12 head" v-if="isDetail">
                <div class="float-left">
                    <h2>Route {{ item.index }}</h2>
                    <span class="booked-text" v-if="(isPassenger && item.booked.active === false) || item.active === false">&nbsp;(bereits abgelaufen)</span>
                </div>

                <div class="head-item float-left">
                    <b-icon icon="arrow-left-right"></b-icon> {{ (item.distance / 1000).toFixed(1).replace('.', ',') }} KM
                </div>
                <div class="head-item float-left">
                    <b-icon icon="hourglass"></b-icon> {{ Math.round(item.duration / 60) }} Min
                </div>

                <div class="float-right d-none">
                    <button class="btn btn-danger float-right" v-on:click="deleteRoute(item)">
                        <b-icon icon="x"></b-icon>
                    </button>
                </div>

                <div class="float-right" v-if="!isPassenger || (isPassenger && item.booked.active) && item.active">
                    <a v-bind:href="item.phone">
                        <button class="btn btn-default btn-phone float-right">
                            <b-icon icon="telephone-outbound-fill"></b-icon>
                        </button>
                    </a>
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="col-12 head" v-bind:class="item.color" v-else>
                <div class="float-left">
                    <h2>Route {{ item.index }}</h2>
                    <span class="booked-text" v-if="item.booked">&nbsp;(bereits gebucht)</span>
                    <span class="booked-text" v-if="(isPassenger && !item.booked.active) || item.active === false">&nbsp;(bereits abgelaufen)</span>
                </div>
                <div class="float-right" v-if="item.active">
                    <div class="head-item float-left">
                        <b-icon icon="arrow-left-right"></b-icon> {{ (item.distance / 1000).toFixed(1).replace('.', ',') }} KM
                    </div>
                    <div class="head-item float-left">
                        <b-icon icon="hourglass"></b-icon> {{ Math.round(item.duration / 60) }} Min
                    </div>
                    <div class="head-item float-left">
                        <b-icon icon="alarm"></b-icon> {{ item.startTime }} Uhr
                    </div>
                </div>
                <div class="clearfix"></div>
            </div>

            <div class="col-12 contact">
                <div class="icon"><b-icon icon="person-fill"></b-icon></div>
                <strong>Kontakt:</strong> {{ item.user.salutation }} {{ item.user.firstname }} {{ item.user.lastname }}
                <span v-if="item.stars > 0">(<b-icon icon="star-fill" class="user-stars"></b-icon> {{ item.stars }})</span>
            </div>
            <div class="col-12 col-sm-6 location">
                <div class="icon"><b-icon icon="geo-alt"></b-icon></div>
                <strong>Von:</strong><br />
                {{ item.origin.street }}<br />
                {{ item.origin.city }}

            </div>
            <div class="col-12 col-sm-6 location">
                <div class="icon"><b-icon icon="geo-alt"></b-icon></div>
                <strong>Nach:</strong><br />
                {{ item.destination.street }}<br />
                {{ item.destination.city }}

            </div>
            <div class="clearfix"></div>

            <div class="col-12 col-sm-12 location" v-if="!isDriver && item.stopDateTime">
                <div class="icon"><b-icon icon="geo-alt"></b-icon></div>
                <strong>Ausgewähltes Datum: {{ new Date(item.stopDateTime).toLocaleDateString() }}</strong>
            </div>
            <div class="col-12 col-sm-12 location" v-else-if="!isDriver && item.booked.stopDateTime">
                <div class="icon"><b-icon icon="geo-alt"></b-icon></div>
                <strong>Ausgewähltes Datum: {{ new Date(item.booked.stopDateTime).toLocaleDateString() }}</strong>
            </div>
            <div class="col-12 col-sm-12 location" v-else-if="item.startDateTime">
                <div class="icon"><b-icon icon="geo-alt"></b-icon></div>
                <strong>Wann?:</strong>
                <div>
                    <span v-if="item.dateType == 0">einmalig am {{ item.startDateTime.toLocaleDateString() }}</span>
                    <span v-if="item.dateType == 1">Montag - Freitag ab {{ item.startDateTime.toLocaleDateString() }}</span>
                    <span v-if="item.dateType == 2">täglich ab {{ item.startDateTime.toLocaleDateString() }}</span>
                </div>
            </div>
            <div class="clearfix"></div>

            <div class="col-12 location stoplocation" v-if="item.booked || isDetail">
                <div class="icon"><b-icon icon="geo"></b-icon></div>
                <strong>Bestätigte Zustiegsstelle:</strong>
                <div class="booked-text">
                    {{ item.booked.street }} | {{ item.booked.city }}<br />
                    {{ item.booked.startTime }} Uhr
                </div>
            </div>
            <div class="col-12 location stoplocation" v-else-if="isOverview">
                <b-alert :show="item.error"
                         variant="danger">{{ item.errorMessage }}</b-alert>

                <div class="icon"><b-icon icon="geo"></b-icon></div>
                <strong>Eingetragene Zustiegsstellen:</strong>
                <br />
                <div class="stoplocations_item">{{ item.origin.street }} | {{ item.origin.city }}<br />{{ item.startTime}} Uhr</div>
                <div class="stoplocations_item" v-for="stop in item.stopLocations" v-bind:key="stop.id">
                    {{ stop.street }} | {{ stop.city }}<br />{{ stop.stopTime}} Uhr
                </div>
            </div>
            <div class="col-12 location stoplocation" v-else>
                <b-alert :show="item.error"
                         variant="danger">{{ item.errorMessage }}</b-alert>

                <div class="icon"><b-icon icon="geo"></b-icon></div>
                <strong>Zustiegsstelle auswählen:</strong>
                <br />
                <select v-model="item.selected" @change="changeDestination(item)" class="select full">
                    <option disabled value="">Bitte auswählen</option>
                    <option value="start">
                        {{ item.origin.street }} | {{ item.origin.city }} | {{ item.startTime}} Uhr
                    </option>
                    <option v-for="stop in item.stopLocations" v-bind:value="stop.id" v-bind:key="stop.id">
                        {{ stop.street }} | {{ stop.city }} | {{ stop.startTime}} Uhr
                    </option>
                </select>

                <datetime v-model="item.stopDateTime"
                          type="date"
                          input-class="d-none"
                          ref="stopDateRef"
                          input-id="stopDate">
                </datetime>
            </div>

            <div class="col-12" v-if="item.booked && hasClickAction">
                <button class="btn btn-danger float-right" v-on:click="deleteRoute(item)">
                    <b-icon icon="x"></b-icon>
                </button>

                <router-link :to="{ name: 'bookedroute', params: { routeId: item.id} }">
                    <button class="btn btn-light float-right">
                        Details
                    </button>
                </router-link>
            </div>
            <div class="col-12 btn-group" v-else-if="hasClickAction">
                <div class="icon"><b-icon icon="chevron-right"></b-icon></div>
                <button class="btn btn-primary btn-block" v-on:click="bookRoute(item)">
                    Mitfahrt bestätigen
                </button>
            </div>

            <div class="col-12" v-if="isDriver && isOverview && item.active">
                <button class="btn btn-danger float-right" v-on:click="deleteRoute(item)">
                    <b-icon icon="x"></b-icon>
                </button>

                <router-link :to="{ name: 'editroute', params: { routeId: item.id} }">
                    <button class="btn btn-light btn-outline-secondary float-right">
                        bearbeiten
                    </button>
                </router-link>
            </div>

            <div class="col-12" v-if="isPassenger && item.booked.active && item.active">
                <button class="btn btn-danger float-right" v-on:click="deleteRoute(item)">
                    <b-icon icon="x"></b-icon>
                </button>

                <router-link :to="{ name: 'bookedroute', params: { routeId: item.id } }">
                    <button class="btn btn-light btn-outline-secondary float-right">
                        Details
                    </button>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ResultsBox",

        props: [
            "item",
            "isDetail",
            "isOverview",
            "isDriver",
            "isPassenger",
            "hasClickAction",
        ],

        created() {
        },

        methods: {
            bookRoute() {
                this.$emit('onBookRoute')
            },

            deleteRoute() {
                this.$emit('onDeleteRoute')
            },

            changeDestination() {
                this.$emit('onChangeDestination')
            },
        },
    }
</script>

<style scoped>

</style>