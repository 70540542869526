<template>
    <b-overlay :show="this.showLoading" rounded="sm">
        <div class="content register lost-password">
            <div class="row">
                <div class="col-12">
                    <h1 class="text-left">Passwort vergessen?</h1>

                    <b-alert :show="isError" fade variant="danger" class="text-left">
                        <div v-for="error in errors" v-bind:key="error.id">{{ error }}</div>
                    </b-alert>

                    <b-alert :show="isSuccess" fade variant="success" class="text-left">
                        <div>{{ this.success }}</div>
                    </b-alert>

                    <form @submit="sendRecovery" @submit.prevent v-if="!tokenCheck">
                        <div class="form-row">
                            <p>Bitte geben Sie Ihre E-Mail Adresse ein, damit Ihr Kennwort wiederhergestellt werden kann.</p>

                            <div class="form-group col-md-12">
                                <input type="email" class="form-control" required placeholder="E-Mail Adresse eingeben" v-model="email" />
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="text-left hint">
                                <strong>Datenschutz</strong><br />
                                Ich habe die <a href="/#/datenschutz">Datenschutzbestimmungen</a>  zur Kenntnis genommen.
                            </div>
                        </div>
                        <button type="submit" class="btn btn-primary btn-block">Passwort wiederherstellen</button>
                    </form>

                    <form @submit="sendReset" @submit.prevent v-else>
                        <div class="form-row">
                            <p>Bitte geben Sie Ihr neues Kennwort ein.</p>

                            <div class="form-group col-md-12">
                                <input type="password" class="form-control" minlength="8" required placeholder="Passwort eingeben" aria-describedby="passwordHelp"  v-model="password">
                                <small class="form-text text-left">Mindestlänge 8 Zeichen</small>
                            </div>
                        </div>

                        <div class="form-row">
                            <p>Bitte geben Sie Ihr neues Kennwort nocheinmal ein.</p>

                            <div class="form-group col-md-12">
                                <input type="password" class="form-control" minlength="8" required placeholder="Passwort nochmal eingeben" aria-describedby="passwordHelp"  v-model="password_double">
                                <small class="form-text text-left">Mindestlänge 8 Zeichen</small>
                            </div>
                        </div>

                        <button type="submit" class="btn btn-primary btn-block">Passwort wiederherstellen</button>
                    </form>
                </div>
            </div>
        </div>
    </b-overlay>
</template>

<script>
    export default {
        name: "LostPassword",
        data() {
            return {
                showLoading: true,
                isError: false,
                isSuccess: false,
                errors: [],
                email: null,
                password: null,
                password_double: null,
                tokenCheck: null,
                token: null,
                success: '',
            }
        },

        created() {
            this.showLoading = false;
            this.tokenCheck = false;
            this.token = null;

            if (typeof(this.$route.params.token) !== 'undefined') {
                this.showLoading = true;
                this.token = this.$route.params.token;
                this.checkToken();
            }
        },

        methods: {
            sendRecovery() {
                if (this.email == null || this.email === '') {
                    this.isError = true;
                    this.errors.push(this.$msg.error["recover-wrong-email"]);
                    return;
                }

                this.showLoading = true;
                this.isError = false;
                this.errors = [];

                fetch(this.$url + '/api/password-recovery', {
                    method: 'POST',
                    cache: 'no-cache',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({
                        'email': this.email,
                    }),
                })
                .then(response => response.json())
                .then(data => {
                    if (data.success === true) {
                        this.isSuccess = true;
                        this.success = this.$msg.success["recover-token"];
                    } else {
                        this.isError = true;
                        if (this.$msg.error[data.error])
                            this.errors.push(this.$msg.error[data.error]);
                        else
                            this.errors.push(this.$msg.error.unknown);
                    }

                    this.showLoading = false;
                })
                .catch(data => {
                    this.isError = true;
                    if (this.$msg.error[data.error])
                        this.errors.push(this.$msg.error[data.error]);
                    else
                        this.errors.push(this.$msg.error.unknown);

                    console.error(data);
                    this.showLoading = false;
                })
            },

            checkToken() {
                this.showLoading = true;
                this.isError = false;
                this.errors = [];

                fetch(this.$url + '/api/password-recovery/token', {
                    method: 'POST',
                    cache: 'no-cache',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({
                        'token': this.token,
                    }),
                })
                .then(response => response.json())
                .then(data => {
                    if (data.success === true) {
                        this.tokenCheck = true;
                    } else {
                        this.isError = true;
                        this.errors.push(this.$msg.error["expires-token"]);
                    }

                    this.showLoading = false;
                })
                .catch(data => {
                    this.isError = true;
                    this.errors.push(this.$msg.error.unknown);

                    console.error(data);
                    this.showLoading = false;
                })
            },

            sendReset() {
                if (this.password !== this.password_double) {
                    this.isError = true;
                    this.errors.push(this.$msg.error["compare-password"]);
                    return;
                }

                this.showLoading = true;
                this.isError = false;
                this.errors = [];

                fetch(this.$url + '/api/password-recovery/reset', {
                    method: 'POST',
                    cache: 'no-cache',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({
                        'token': this.token,
                        'plain_password': this.password,
                        'plain_password_double': this.password_double,
                    }),
                })
                .then(response => response.json())
                .then(data => {
                    if (data.success === true) {
                        this.isSuccess = true;
                        this.success = this.$msg.success["password-reset"]
                    } else {
                        if (data.error === 'wrong-token') {
                            this.token = false;
                        }

                        this.isError = true;
                        this.errors.push(this.$msg.error[data.error]);
                    }

                    this.showLoading = false;
                })
                .catch(data => {
                    this.isError = true;
                    this.errors.push(this.$msg.error.unknown);

                    console.error(data);
                    this.showLoading = false;
                })
            },
        },
    }
</script>

<style scoped>

</style>