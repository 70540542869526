<template>
    <b-overlay :show="this.showLoading" rounded="sm">
        <div class="content routes voting">
            <div class="row">
                <div class="col-12" v-if="!this.isVoted">
                    <h1 class="text-left">Bitte bewerte die Fahrt mit {{ username }}?</h1>

                    <b-alert :show="isError" fade variant="danger" class="text-left">
                        <div v-for="error in errors" v-bind:key="error.id">{{ error }}</div>
                    </b-alert>

                    <div class="vote">
                        <b-icon icon="star" @click="starClick(1, true)" v-if="!this.star1 && !this.star2"></b-icon>
                        <b-icon icon="star-fill" @click="starClick(1, false)" v-if="this.star1 || this.star2"></b-icon>

                        <div class="space" v-if="this.isPassenger"></div>

                        <b-icon icon="star" @click="starClick(2, true)" v-if="this.isPassenger && !this.star2"></b-icon>
                        <b-icon icon="star-fill" @click="starClick(2, false)" v-if="this.isPassenger && this.star2"></b-icon>
                    </div>
                    <p>&nbsp;</p><br />
                    <p>&nbsp;</p>

                    <b-button block variant="primary" @click="sendVote">
                        Bewertung absenden
                    </b-button>
                </div>

                <div class="col-12" v-else>
                    <div class="result-list text-left row">
                        <div class="result col-12 success-window">
                            <div class="box text-center">
                                <b-icon icon="check"></b-icon>
                                <br />
                                <h2>Vielen Dank für Ihre Bewertung!</h2>
                                <br />

                                <router-link :to="{ name: 'routeoverview' }">
                                    <button class="btn btn-block btn-primary">
                                        zurück zur Übersicht
                                    </button>
                                </router-link>
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-overlay>
</template>

<script>
    import bring2API from "@/api";

    export default {
        name: "Vote",

        data() {
            return {
                showLoading: false,
                isPassenger: null,
                isDriver: null,
                routeId: null,
                star1: false,
                star2: false,
                isError: false,
                isSuccess: false,
                errors: [],
                items: [],
                username: '',
                isVoted: false,
            }
        },

        created() {
            if (typeof(this.$route.params.type) === 'undefined') {
                this.isError = true;
                this.errors.push(this.$msg.error.unknown);
                return false;
            }

            if (typeof(this.$route.params.routeId) === 'undefined') {
                this.isError = true;
                this.errors.push(this.$msg.error.unknown);
                return false;
            }

            if (this.$route.params.routeId > 0) {
                this.routeId = this.$route.params.routeId;
            }

            if (this.$route.params.type === 'driver') {
                this.isDriver = true;
            } else if (this.$route.params.type === 'passenger') {
                this.isPassenger = true;
                bring2API.getBookedRoute(this.routeId, this);
            }
        },

        methods: {
            getRoute(data) {
                if (data.id === this.routeId) {
                    bring2API.getRoute(data.routeId, this);
                } else {
                    this.isError = true;
                    this.errors.push(this.$msg.error.unknown);
                }
            },

            setDirections(data) {
                this.username = data.user.salutation + " " + data.user.lastname;
            },

            sendVote() {
                var stars = 0;
                if (this.star1 === true) stars++;
                if (this.star2 === true) stars++;

                fetch(this.$url + '/api/vote', {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({
                        'id': this.routeId,
                        'stars': stars
                    })
                })
                .then(response => response.json())
                .then(data => {
                    this.errors = [];
                    this.isError = false;
                    this.isSuccess = false;

                    if (data.success === true) {
                        this.isVoted = true;
                        this.isSuccess = true;
                    } else {
                        this.errors.push('Speichern der Bewertung fehlgeschlagen!');
                        this.isError = true;
                    }

                    this.showLoading = false;
                })
                .catch(data => {
                    this.errors.push(data);
                    this.isError = true;
                    this.showLoading = false;
                });
            },

            starClick(star, active) {
                if (star === 1) {
                    if (this.star1 === true && active === false) {
                        this.star1 = false;

                        if (this.star2 === true) {
                            this.star2 = false;
                        }
                    } else if (this.star1 === false && active === true) {
                        this.star1 = true;
                    }
                }

                if (star === 2) {
                    if (this.star2 === true && active === false) {
                        this.star2 = false;
                    } else if (this.star2 === false && active === true) {
                        this.star2 = true;

                        if (this.star1 === false) {
                            this.star1 = true;
                        }
                    }
                }
            },
        },
    }
</script>

<style scoped>

</style>