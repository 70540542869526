<template>
    <b-overlay :show="this.showLoading" rounded="sm">
        <div class="content routes">
            <div class="row">
                <div class="col-12">
                    <div class="distance filter">
                        <div class="float-left text-left text">
                            Routen zum mitfahren
                        </div>

                        <div class="float-right text-right filter">
                            Umkreis
                            <img src="../assets/circle-icon.png" alt="Umkreis ändern" />
                            <select v-model="circleDistance" @change="getBookedRoutes">
                                <option value="5" selected>5KM</option>
                                <option value="15" selected>15KM</option>
                                <option value="30" selected>30KM</option>
                                <option value="100" selected>100KM</option>
                            </select>
                        </div>
                        <div class="clearfix"></div>
                    </div>

                    <GmapMap ref="mapRef"
                             :center="{ lat:48.358492, lng:10.7914007 }"
                             :zoom="12" @idle="changedBounds">
                        <GmapMarker :position="this.currentLocation"></GmapMarker>
                        <GmapMarker v-for="(marker, index) in markers"
                                    :key="index"
                                    :position="marker.position"
                                    :icon="marker.icon"
                                    :title="marker.title"
                                    @click="markerClick(marker.routeId)"
                        ></GmapMarker>
                    </GmapMap>

                    <div class="result-list text-left row">
                        <div class="col-12">
                            <div class="header">
                                <div class="float-left text" v-if="this.items.length > 0 && this.items">Buchbare Routen</div>
                                <div class="float-left text" v-else>Es wurden keine Routen in Ihrem Umkreis gefunden</div>

                                <div class="float-right filter"></div>
                                <div class="clearfix"></div>
                            </div>
                        </div>

                        <div class="result col-lg-6 col-12" v-for="item in items" v-bind:key="item.id">
                            <ResultBox :item="item" :has-click-action="true" ref="resultBox"
                                       @onBookRoute="bookRoute(item)"
                                       @onDeleteRoute="deleteRoute(item)"
                                       @onChangeDestination="changeDestination(item)"></ResultBox>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-overlay>
</template>

<script>
    import ResultBox from "@/components/snippets/ResultBox";
    import bring2API from "@/api";

    export default {
        name: "Routes",
        components: {
            ResultBox
        },

        data() {
            return {
                markers: [],
                map: null,
                me: null,
                icon: null,
                items: [],
                currentLocation: null,
                label: {color: '#000', fontSize: '12px', fontWeight: '600', text: '123' },
                title: "12",
                circleDistance: 15,
                booked: [],
                loading: false,
                vm: null,
                showLoading: true,
                bookedDate: null
            }
        },

        mounted () {
            this.$refs.mapRef.$mapPromise.then((map) => {
                this.vm = this;

                // set current location
                try {
                    this.currentLocation = new window.google.maps.LatLng(
                        this.$store.getters.getCurrentLocation.coords.latitude,
                        this.$store.getters.getCurrentLocation.coords.longitude
                    );
                } catch {
                    // default: lat:48.358492, lng:10.7914007
                    this.currentLocation = new window.google.maps.LatLng(
                        48.358492,
                        10.7914007
                    );
                }

                // set directory service
                this.directionsService = new window.google.maps.DirectionsService();
                this.icon = {
                    path: "M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0",
                    fillColor: '#FF0000',
                    fillOpacity: 1,
                    anchor: new window.google.maps.Point(0,0),
                    strokeWeight: 0,
                    scale: 0.5
                };
                this.map = map;
                this.map.panTo(this.currentLocation);
            });
        },

        methods: {
            getBookedRoutes() {
                this.showLoading = true;
                this.items = [];

                fetch(this.$url + '/api/route-booked?cb='+ new Date().getTime(), {
                    method: 'GET',
                    cache: 'no-cache',
                    headers: {'Content-Type': 'application/json'},
                })
                .then(response => response.json())
                .then(data => {
                    this.booked = data;
                    bring2API.getRoutes(this.vm, this);
                    this.showLoading = false;
                })
                .catch(data => {
                    console.error(data);
                    this.showLoading = false;
                })
            },

            setDirections(route, vm, index) {
                var rendererOptions = {
                    // preserveViewport: true,
                    suppressMarkers: true,
                    routeIndex: index,
                    polylineOptions: {
                        strokeColor: this.$colors[index]
                    }
                };
                var me = this;

                var directionsDisplay = new window.google.maps.DirectionsRenderer(rendererOptions);
                directionsDisplay.setMap(this.map);

                var waypoints = [];
                if(typeof(route.routeStopLocations) !== 'undefined') {
                    for (var i = 0; i < route.routeStopLocations.length; i++) {
                        waypoints[i] = {
                            location: route.routeStopLocations[i].location,
                            stopover: true
                        }
                    }
                }

                vm.directionsService.route({
                    origin: route.startLocation,
                    waypoints: waypoints,
                    destination: route.stopLocation,
                    travelMode: 'DRIVING'
                }, function (response, status) {
                    if (status === 'OK') {
                        var legs = response.routes[0].legs;
                        var startAddress = legs[0].start_address.split(',');
                        var endAddress = legs[legs.length-1].end_address.split(',');
                        var stopLocations = [];

                        var c = 0;
                        for(var stopLocation of route.routeStopLocations) {
                            var stopAddress = legs[c+1].start_address.split(',');

                            stopLocations.push({
                                id: stopLocation.id,
                                startTime: stopLocation.stopTime,
                                street: stopAddress[0],
                                city: stopAddress[1],
                            });

                            c++;
                        }

                        var startDate = new Date(route.startDateTime);
                        var startTime = startDate.toLocaleTimeString();

                        var item = {
                            id: route.id,
                            index: (index +1),
                            color: me.$colors[index],
                            distance: route.distance,
                            duration: route.duration,
                            startTime: startTime.substring(0, startTime.length-3),
                            startDateTime: startDate,
                            dateType: route.dateType,
                            user: route.user,
                            origin: {
                                street: startAddress[0],
                                city: startAddress[1]
                            },
                            destination: {
                                street: endAddress[0],
                                city: endAddress[1]
                            },
                            stopLocations: stopLocations,
                            stars: route.stars,
                            selected: '',
                            error: false,
                            errorMessage: '',
                            booked: false,
                        };

                        item = me.computeBooked(item);

                        me.items.push(item);

                        var icon = me.icon;
                        icon.fillColor = me.$colors[index];

                        var polyline = new window.google.maps.Polyline({
                            path: [],
                            strokeColor: '#FF0000',
                            strokeWeight: 3
                        });
                        polyline.setPath([]);
                        for (var i = 0; i < legs.length; i++) {
                            var steps = legs[i].steps;
                            for (var j = 0; j < steps.length; j++) {
                                var nextSegment = steps[j].path;
                                for (var k = 0; k < nextSegment.length; k++) {
                                    polyline.getPath().push(nextSegment[k]);
                                }
                            }
                        }

                        me.setLoading();

                        directionsDisplay.setDirections(response);
                        me.putMarkerOnRoute(50, polyline, legs[0].distance.value, icon, route.id);
                    } else {
                        console.error('Directions request failed due to ' + status)
                    }
                });
            },

            putMarkerOnRoute(percentage, polyline, totalDistance, icon, routeId) {
                var distance = (percentage / 100) * totalDistance;

                this.markers.push({
                    position: this.getPointAtDistance(distance, polyline),
                    icon: icon,
                    routeId: routeId
                });
            },

            markerClick(routeId) {
                console.debug(routeId);
            },

            getPointAtDistance (distance, polyline) {
                if (distance == 0) return polyline.getPath().getAt(0);
                if (distance < 0) return null;
                if (polyline.getPath().getLength() < 2) return null;
                var dist = 0;
                var olddist = 0;
                for (var i = 1;
                     (i < polyline.getPath().getLength() && dist < distance); i++) {
                    olddist = dist;
                    dist += window.google.maps.geometry.spherical.computeDistanceBetween(polyline.getPath().getAt(i), polyline.getPath().getAt(i - 1));
                }
                if (dist < distance) {
                    return null;
                }
                var p1 = polyline.getPath().getAt(i - 2);
                var p2 = polyline.getPath().getAt(i - 1);
                var m = (distance - olddist) / (dist - olddist);
                return new window.google.maps.LatLng(p1.lat() + (p2.lat() - p1.lat()) * m, p1.lng() + (p2.lng() - p1.lng()) * m);
            },

            bookRoute (item) {
                if (item.selected === '') {
                    item.error = true;
                    item.errorMessage = "Bitte wählen Sie eine Zustriegstelle!";

                    return;
                }

                item.error = false;
                fetch(this.$url + '/api/route/'+ item.id +'/book', {
                    method: 'POST',
                    cache: 'no-cache',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({
                        'stop_route_id': item.selected,
                        'stop_date_time': item.stopDateTime
                    }),
                })
                .then(response => response.json())
                .then(data => {
                    if (data.id > 0) {
                        this.booked.push(data);
                        item = this.computeBooked(item);
                    } else {
                        item.error = false;
                        item.errorMessage = "Fehler beim bestätigen der Mitfahrt!";
                    }
                })
                .catch(() => {
                    item.error = false;
                    item.errorMessage = "Fehler beim bestätigen der Mitfahrt!";
                })
            },

            deleteRoute(item) {
                bring2API.deleteRoute(item, this);
            },

            computeBooked(item) {
                var me = this;

                item.booked = false;

                if (me.booked !== '' && me.booked != null && me.booked.length > 0) {
                    var bookedValue = me.booked.filter(d => d.routeId === item.id);
                    if (bookedValue.length > 0 && item.booked === false) {
                        if (bookedValue[0].stopRouteId == null) {
                            item.booked = item.origin;
                            item.booked.startTime = item.startTime;
                            item.booked.bookedId = bookedValue[0].id;
                            item.booked.stopDateTime = bookedValue[0].stopDateTime;
                        } else {
                            var stopBooked = item.stopLocations.filter(s => s.id === bookedValue[0].stopRouteId);
                            if (stopBooked.length > 0 && item.booked === false) {
                                item.booked = stopBooked[0];
                                item.booked.bookedId = bookedValue[0].id;
                                item.booked.stopDateTime = bookedValue[0].stopDateTime;
                            }
                        }
                    }
                }

                return item;
            },

            setLoading() {
                if (!this.loading) {
                    this.loading = true;
                    window.setTimeout(function() {
                        this.loading = false;
                    }, 1000);
                }
            },

            changedBounds() {
                if (!this.loading) {
                    this.currentLocation = this.map.center;
                    // set route to direction service
                    this.getBookedRoutes();
                }
            },

            changeDestination(item) {
                var resultBox = this.$refs.resultBox;
                var stopDate = resultBox[0].$refs.stopDateRef;
                stopDate.minDatetime = item.startDateTime.toISOString();

                if (new Date() > item.startDateTime) {
                    stopDate.minDatetime = new Date().toISOString();
                }

                // disable weekend on data type 1
                if (item.dateType === 1) {
                    stopDate.excludedWeekDays = [ 6, 7 ];
                }

                var btn = stopDate.$el.querySelector('input');
                btn.click();
            },
        }
    }
</script>