<template>
    <b-overlay :show="this.showLoading" rounded="sm">
        <div class="content routes overview">
            <div class="row">
                <div class="col-12">
                    <h1 class="text-left">Routen Übersicht</h1>

                    <b-alert :show="isError" fade variant="danger" class="text-left">
                        <div v-for="error in errors" v-bind:key="error.id">{{ error }}</div>
                    </b-alert>
                </div>

                <div class="col-12">
                    <div class="accordion" role="tablist">
                        <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.accordion-1 variant="primary">
                                    Ihre Routen als Fahrer
                                </b-button>
                            </b-card-header>
                            <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                    <div class="result-list text-left row">
                                        <div class="result col-12" v-for="item in items.driver" v-bind:key="item.id">
                                            <ResultBox :item="item" :is-overview="true" :is-driver="true"
                                                       @onBookRoute="bookRoute(item)"
                                                       @onDeleteRoute="deleteDriverRoute(item)"></ResultBox>
                                        </div>
                                        <div class="result col-12" v-if="items.driver.length < 1">
                                            Sie haben noch keine Routen als Fahrer geplant.
                                        </div>
                                    </div>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                        <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.accordion-2 variant="primary">
                                    Ihre Routen als Mitfahrer
                                </b-button>
                            </b-card-header>
                            <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                    <div class="result-list text-left row">
                                        <div class="result col-12" v-for="item in items.passenger" v-bind:key="item.unique">
                                            <ResultBox :item="item" :is-detail="true" :is-passenger="true"
                                                       @onBookRoute="bookRoute(item)"
                                                       @onDeleteRoute="deleteRoute(item)"></ResultBox>
                                        </div>
                                        <div class="result col-12" v-if="items.passenger.length < 1">
                                            Sie sind noch bei keiner Fahrt mitgefahren.
                                        </div>
                                    </div>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                    </div>
                </div>
            </div>
        </div>
    </b-overlay>
</template>

<script>
    import ResultBox from "@/components/snippets/ResultBox";
    import bring2API from "@/api";

    export default {
        name: "RoutesOverview",

        components: {
            ResultBox
        },

        data() {
            return {
                showLoading: true,
                isError: false,
                isSuccess: false,
                errors: [],
                items: {
                    driver: [],
                    passenger: [],
                },
            }
        },

        created() {
            this.getMyRoutes();
        },

        methods: {
            getMyRoutes() {
                fetch(this.$url + '/api/my-routes?cb='+ new Date().getTime(), {
                    method: 'GET',
                    cache: 'no-cache',
                    headers: {'Content-Type': 'application/json'},
                })
                .then(response => response.json())
                .then(data => {
                    if (data.driver && data.passenger) {
                        this.normalizeDriver(data.driver.reverse());
                        this.normalizePassenger(data.passenger.reverse());
                    }

                    this.showLoading = false;
                })
                .catch(data => {
                    console.error(data);
                })
            },

            normalizeDriver(data) {
                for (var driver of data) {
                    var temp = driver;
                    temp.origin = {
                        street: driver.startStreet,
                        city: driver.startCity,
                    };

                    temp.destination = {
                        street: driver.stopStreet,
                        city: driver.stopCity,
                    };

                    temp.stopLocations = driver.routeStopLocations;
                    temp.startDateTime = new Date(driver.startDateTime);

                    this.items.driver.push(temp);
                }
            },

            normalizePassenger(data) {
                var i = 1;
                for (var passenger of data) {
                    var temp = passenger.route;
                    temp.unique = temp.id + i;
                    temp.index = i;
                    temp.origin = {
                        street: temp.startStreet,
                        city: temp.startCity,
                    };

                    temp.destination = {
                        street: temp.stopStreet,
                        city: temp.stopCity,
                    };

                    temp.stopLocations = temp.routeStopLocations;
                    temp.booked = {
                        street: '',
                        city: '',
                        startTime: '',
                        active: passenger.bookedRoute.active,
                    };

                    if (passenger.bookedRoute.stopRouteId == null) {
                        temp.booked = {
                            id: passenger.bookedRoute.id,
                            street: temp.startStreet,
                            city: temp.startCity,
                            startTime: temp.startTime,
                            stopDateTime: new Date(passenger.bookedRoute.stopDateTime),
                            active: passenger.bookedRoute.active,
                        };

                    } else {
                        var stopBooked = temp.stopLocations.filter(s => s.id === passenger.bookedRoute.stopRouteId);
                        if (stopBooked !== null && stopBooked.length > 0) {
                            temp.booked = {
                                id: passenger.bookedRoute.id,
                                street: stopBooked[0].street,
                                city: stopBooked[0].city,
                                startTime: stopBooked[0].stopTime,
                                stopDateTime: new Date(stopBooked[0].stopDateTime),
                                active: passenger.bookedRoute.active,
                            };
                        }
                    }

                    temp.stopDateTime = new Date(temp.booked.stopDateTime);

                    console.log(temp);

                    i++;
                    this.items.passenger.push(temp);
                }
            },

            deleteRoute(item) {
                item.booked.bookedId = item.booked.id;
                bring2API.deleteRoute(item, this);
            },

            deleteDriverRoute(item) {
                bring2API.deleteDriverRoute(item, this);
            },

            bookRoute() {}
        },
    }
</script>