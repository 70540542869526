<template>
    <div class="content overview">
        <div class="logo-container">
            <img src="../assets/hauptmenue.png" class="overview-menu-icon" />
            <img src="../assets/fahren_oder_mitfahren.png" class="overview-route-icon" />
        </div>
        <div class="button-container">
            <router-link to="/routen"  v-slot="{ href, navigate }">
                <a :href="href" @click="navigate">
                    <button class="btn btn-block btn-primary">Route zum mitfahren</button>
                </a>
            </router-link>
            <router-link to="/routen/anlegen"  v-slot="{ href, navigate }">
                <a :href="href" @click="navigate" class="btn-margin">
                    <button class="btn btn-block btn-primary">Neue Route eintragen</button>
                </a>
            </router-link>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Overview",
        data() {
            return {
                errorStr: null,
            }
        },
        created() {
        },
    }
</script>
