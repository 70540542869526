<template>
    <b-overlay :show="this.showLoading" rounded="sm">
        <div class="content routes create">
            <div class="row">
                <div class="col-12">
                    <h1 class="text-left">Neue Route erstellen</h1>

                    <b-alert :show="isError" fade variant="danger" class="text-left">
                        <div v-for="error in errors" v-bind:key="error.id">{{ error }}</div>
                    </b-alert>

                    <b-alert :show="isSuccess" fade variant="success" class="text-left">
                        <div>Ihre Route wurde gespeichert!</div>
                    </b-alert>

                    <div class="gmap">
                        <div class="input-group">
                            <gmap-autocomplete class="gmapsSearch start form-control" placeholder="Startpunk eingeben"
                                               @place_changed="setStartAddress" :value="item.startAddress"
                                               ref="startAddressRef">
                            </gmap-autocomplete>

                            <div class="input-group-append">
                                <button class="clock-btn btn-secondary" v-on:click="openDateTimePopup('startTimeRef', false)">
                                    <b-icon icon="clock" aria-hidden="true"></b-icon>
                                </button>

                                <datetime v-model="item.startDateTime"
                                          type="datetime"
                                          input-class="d-none"
                                          ref="startTimeRef"
                                          input-id="startDate"
                                          title="Wann soll deine erste Fahrt starten?">
                                </datetime>
                            </div>
                        </div>

                        <div class="input-group">
                            <gmap-autocomplete class="gmapsSearch end form-control fade show" v-if="this.item.startAddress"
                                               @place_changed="setStopAddress"  placeholder="Zielort eingeben"
                                               :value="item.stopAddress">
                            </gmap-autocomplete>
                        </div>

                        <div class="input-group" v-for="(wayPoint, index) in item.stopLocations" v-bind:key="wayPoint.index">
                            <gmap-autocomplete class="gmapsSearch form-control stop fade show" placeholder="Zustiegsort eingeben"
                                               @place_changed="setWaypoint" :value="wayPoint.address">
                            </gmap-autocomplete>

                            <div class="input-group-append" v-if="wayPoint.lat && wayPoint.lng">
                                <button class="btn btn-danger" v-on:click="deleteWaypoint(index)">
                                    <b-icon icon="x"></b-icon>
                                </button>
                            </div>
                            <div class="input-group-append">
                                <b-form-timepicker
                                        v-model="wayPoint.startDateTime"
                                        v-bind="labels[locale] || {}"
                                        :locale="locale"
                                        button-only
                                        right
                                        ref="stopsTimeRef"
                                ></b-form-timepicker>
                            </div>
                        </div>

                        <div class="card mb-12 padding">
                            <div class="row">
                                <div class="col-12 col-md-6 text-left">
                                    Wie oft fahren Sie diese Route?
                                </div>

                                <div class="col-12 col-md-6">
                                    <select v-model="routeDateType" class="full-width">
                                        <option value="0" selected="selected">einmalig</option>
                                        <option value="1">Montag - Freitag</option>
                                        <option value="2">täglich</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <GmapMap ref="mapRef"
                                 :center="{ lat:48.358492, lng:10.7914007 }"
                                 :zoom="14">
                            <GmapMarker v-for="(marker, index) in markers"
                                        :key="index"
                                        :position="marker.position"
                                        :draggable="true"
                                        :ondragend="dragended"
                                        @dragend="dragended"
                            ></GmapMarker>
                        </GmapMap>

                        <button class="btn btn-primary btn-block btn-margin" v-on:click="createRouteApi()">
                            Route speichern
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </b-overlay>
</template>

<script>
    import bring2API from "@/api";
    import bring2Gmap from "@/gmap";

    export default {
        name: "CreateRoutes",

        data() {
            return {
                routeDateType: 1,
                startPosition: null,
                stopPosition: null,
                geolocation: null,
                stopPoints: [],
                markers: [],
                isError: false,
                isSuccess: false,
                errors: [],
                isEditing: false,
                route: {
                  distance: 0,
                  duration: 0,
                },
                time: {
                    start: '08:00:00',
                    stops: [],
                },
                map: null,
                directionsService: null,
                directionsDisplay: null,
                vm: null,
                locale: 'de',
                labels: {
                    de: {
                        labelHours: 'Stunden',
                        labelMinutes: 'Minuten',
                        labelSeconds: 'Sekunden',
                        labelIncrement: 'Erhöhen',
                        labelDecrement: 'Verringern',
                        labelSelected: 'Ausgewählte Zeit',
                        labelNoTimeSelected: 'Keine Zeit ausgewählt',
                        labelCloseButton: 'speichern'
                    },
                },
                showLoading: true,
                item: {
                    stopDateTime: '',
                    startDateTime: new Date().setHours(8),
                    startAddress: '',
                    stopAddress: '',
                    stopLocations: [],
                    routeDateType: 1
                },
                editRouteId: null,
                interval: null,
            }
        },

        mounted() {
            this.isEditing = false;
            this.editRouteId = null;

            if (this.$route.params.routeId > 0) {
                this.isEditing = true;
                this.editRouteId = this.$route.params.routeId;
            }


            this.$refs.mapRef.$mapPromise.then((map) => {
                this.map = map;

                var currentLocation = this.$store.getters.getCurrentLocation;
                if (currentLocation !== null) {
                    this.map.panTo({
                        lat: currentLocation.coords.latitude,
                        lng: currentLocation.coords.longitude
                    });
                }

                this.$refs.startAddressRef.$el.focus();

                if (this.isEditing) {
                    this.directionsService = new window.google.maps.DirectionsService();
                    bring2API.getRoute(this.editRouteId, this);
                }
            });

            this.showLoading = false;
        },

        methods: {
            setDirections(item, index) {
                bring2Gmap.setDirections(this.directionsService, this.map, item, index, this, false);
            },

            setItem(item) {
                item.startAddress = item.origin.street + item.origin.city;
                item.stopAddress = item.destination.street + item.origin.city;

                for (var i = 0; i < item.stopLocations.length; i++) {
                    item.stopLocations[i].address = item.stopLocations[i].street + item.stopLocations[i].city;
                }

                item.stopLocations.push({});

                this.item = item;
                console.log("item", "blaaa", this.item);
            },

            setStartAddress: function (addressData) {
                var address = this.validateAdress(addressData);
                this.item.startAddress = addressData.formatted_address;

                if (address !== false) {
                    this.item.startAddress = addressData.name+ ', ' + addressData.formatted_address;

                    this.startPosition = this.getAddressData(addressData);
                    this.startPosition.address = address;

                    if (this.startPosition) {
                        if (this.stopPosition) {
                            this._setDirections();
                        }

                        this.openDateTimePopup('startTimeRef', false);
                    }
                }
            },

            setStopAddress: function (addressData) {
                var address = this.validateAdress(addressData);
                this.item.stopAddress = addressData.formatted_address;

                if (address !== false) {
                    this.item.stopAddress = addressData.name + ', ' + addressData.formatted_address;
                    this.stopPosition = this.getAddressData(addressData);
                    this.stopPosition.address = address;

                    this.addEmptyWaypoints();

                    if (this.stopPosition) {
                        this._setDirections();
                    }
                }
            },

            setWaypoint: function (addressData) {
                var address = this.validateAdress(addressData, true);
                this.item.stopLocations[this.item.stopLocations.length - 1].address = addressData.formatted_address;

                if (address !== false) {
                    this.item.stopLocations[this.item.stopLocations.length - 1] = this.getAddressData(addressData);
                    this.item.stopLocations[this.item.stopLocations.length - 1].street = address.route + " " + address.street_number;
                    this.item.stopLocations[this.item.stopLocations.length - 1].city = address.postal_code + " " + address.locality;
                    this.item.stopLocations[this.item.stopLocations.length - 1].address = addressData.name + ', ' + addressData.formatted_address;

                    if (this.item.stopLocations.length > 1) {
                        this.item.stopLocations[this.item.stopLocations.length - 1].startDateTime = this.item.stopLocations[this.item.stopLocations.length - 2].startDateTime;
                    } else {
                        this.item.stopLocations[this.item.stopLocations.length - 1].startDateTime = this.item.startDateTime;
                    }

                    this.openDateTimePopup('stopsTimeRef', true);

                    // set new route in map
                    this._setDirections();
                    this.addEmptyWaypoints();
                }
            },

            getAddressData: function (addressData) {
                if (addressData) {
                    var position = {
                        lat: addressData.geometry.location.lat(),
                        lng: addressData.geometry.location.lng(),
                    };

                    // this.markers.push({ position: position });
                    this.map.panTo(position);

                    return position;
                }

                return null;
            },

            _setDirections: function() {
                if (this.isEditing) {
                    if (this.directionsDisplay != null) {
                        this.directionsDisplay.setMap(null);
                        this.directionsDisplay = null;
                    }
                }

                this.directionsService = new window.google.maps.DirectionsService();
                this.directionsDisplay = new window.google.maps.DirectionsRenderer();
                this.directionsDisplay.setMap(this.map);

                var waypoints = [];
                for(var i = 0; i < this.item.stopLocations.length; i++) {
                    if (this.item.stopLocations[i].lat && this.item.stopLocations[i].lng) {
                        waypoints[i] = {
                            location: this.item.stopLocations[i],
                            stopover: true
                        }
                    }
                }

                var me = this;
                me.directionsService.route({
                    origin: this.item.startAddress,
                    waypoints: waypoints,
                    destination: this.item.stopAddress,
                    travelMode: 'DRIVING'
                }, function (response, status) {
                    if (status === 'OK') {
                        me.directionsDisplay.setDirections(response);
                        var legs = response.routes[0].legs,
                            distance = 0,
                            duraction = 0;

                        for(var i = 0; i < legs.length; i++) {
                            distance += legs[i].distance.value;
                            duraction += legs[i].duration.value;
                        }

                        me.route.distance = distance;
                        me.route.duration = duraction;
                    } else {
                        console.error('Directions request failed due to ' + status)
                    }
                })
            },

            // create route api
            createRouteApi: function () {
                this.showLoading = true;

                var waypoints = [];
                for(var i = 0; i < this.item.stopLocations.length; i++) {
                    if (this.item.stopLocations[i].lat && this.item.stopLocations[i].lng) {
                        var splitTime = this.item.stopLocations[i].startDateTime.split(':');
                        var stopDateTime = new Date(this.item.startDateTime);
                        stopDateTime.setHours(splitTime[0]);
                        stopDateTime.setMinutes(splitTime[1]);

                        this.item.stopLocations[i].time = stopDateTime;
                        waypoints[i] = this.item.stopLocations[i];
                    }
                }

                var method = 'POST';
                var id = "";

                if (this.isEditing) {
                    method = 'PUT';
                    id = "/" + this.editRouteId;

                    if (this.startPosition == null) {
                        this.startPosition = this.item.startLocation;
                        this.startPosition.address = this.item.origin;
                    }

                    if (this.stopPosition == null) {
                        this.stopPosition = this.item.stopLocation;
                        this.stopPosition.address = this.item.destination;
                    }

                    if (this.route.distance == null || this.route.distance < 1) {
                        this.route.distance = this.item.distance;
                    }

                    if (this.route.duration == null || this.route.duration < 1) {
                        this.route.duration = this.item.duration;
                    }
                }

                fetch(this.$url + '/api/route' + id, {
                    method: method,
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({
                        'start_location': this.startPosition,
                        'stop_location': this.stopPosition,
                        'start_address': this.item.startAddress,
                        'stop_address': this.item.stopAddress,
                        'distance': this.route.distance,
                        'duration': this.route.duration,
                        'start_time': this.item.startDateTime,
                        'date_type': this.routeDateType,
                        'waypoints': waypoints,
                    })
                })
                .then(response => response.json())
                .then(data => {
                    this.isError = false;
                    this.isSuccess = false;
                    this.errors = [];

                    if (data.id > 0) {
                        this.isSuccess = true;
                    } else {
                        if (this.$msg.error[data.error])
                            this.errors.push(this.$msg.error[data.error]);
                        else
                            this.errors.push(this.$msg.error["unknown"]);

                        this.isError = true;
                    }

                    this.showLoading = false;
                    window.scrollTo(0,0);
                })
                .catch(data => {
                    this.errors.push(data);
                    this.isError = true;
                    console.error(data);
                    this.showLoading = false;
                    window.scrollTo(0,0);
                })
            },

            parseAddress: function (address_components) {
                var components = {};
                address_components.map((value) => {
                    value.types.map((value2) => {
                        if (typeof(value.long_name) === 'undefined')
                            value.long_name = '';

                        if (typeof(value.short_name) === 'undefined')
                            value.short_name = '';

                        components[value2] = value.long_name;
                        if (value2 === 'country')
                            components.country_id = value.short_name;
                        if (value2 === 'administrative_area_level_1')
                            components.state_code = value.short_name;
                    })
                });

                return components;
            },

            validateAdress(addressData, separate) {
                this.isError = false;
                this.errors = [];

                var parsedAddress = this.parseAddress(addressData.address_components);

                if (typeof(parsedAddress.route) === 'undefined') {
                    this.isError = true;
                    this.errors.push(this.$msg.error["no-street"]);

                    return false;
                }

                if (typeof(parsedAddress.street_number) === 'undefined') {
                    this.isError = true;
                    this.errors.push(this.$msg.error["no-street-number"]);

                    return false;
                }

                if (typeof(parsedAddress.locality) === 'undefined') {
                    this.isError = true;
                    this.errors.push(this.$msg.error["no-city"]);

                    return false;
                }

                if (typeof(parsedAddress.postal_code) === 'undefined') {
                    this.isError = true;
                    this.errors.push(this.$msg.error["no-zip"]);

                    return false;
                }

                if (separate) {
                    return parsedAddress;
                }

                return {
                    street: parsedAddress.route+ " " + parsedAddress.street_number,
                    city: parsedAddress.postal_code+ " " + parsedAddress.locality,
                };
            },

            addEmptyWaypoints() {
                for (var waypoint in this.item.stopLocations) {
                    if (!waypoint.lat && !waypoint.lng) {
                        return true;
                    }
                }

                this.item.stopLocations.push({id: this.item.stopLocations.length});
                this.time.stops[this.item.stopLocations.length - 1] = this.time.start;

                return  false;
            },

            deleteWaypoint(index) {
                if (typeof(index) != "undefined") {
                    this.item.stopLocations.splice(index, 1);
                    this._setDirections();
                }
            },

            openDateTimePopup(popupRef, lastItem) {
                var btn = null;
                var elem = null;

                if (lastItem) {
                    elem = this.$refs[popupRef][this.item.stopLocations.length - 1];
                } else {
                    elem = this.$refs[popupRef];
                }

                if (!this.isEditing) {
                    elem.minDatetime = new Date().toISOString();
                }
                elem.backdropClick = false;

                btn = elem.$el.querySelector('input');
                btn.click();
            },
        }
    }
</script>