<template>
    <b-overlay :show="this.showLoading" rounded="sm">
        <div class="content booked-route">
            <div class="row">
                <div class="col-md-12">
                    <b-alert :show="isError" fade variant="danger" class="text-left">
                        <div v-for="error in errors" v-bind:key="error.id">{{ error }}</div>
                    </b-alert>

                    <div class="head">
                        <h1 class="float-left text-left">Meine gebuchte Mitfahrt</h1>
                        <div class="float-right">
                            <router-link :to="{ name: 'routen' }">
                                zurück
                            </router-link>
                        </div>
                        <div class="clearfix"></div>
                    </div>

                    <div v-if="!detail">
                        <div class="result-list text-left row">
                            <div class="result col-12" v-if="item">
                                <ResultBox :item="item" :is-detail="true"
                                           @onBookRoute="bookRoute(item)"
                                           @onDeleteRoute="deleteRoute(item)"></ResultBox>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <GmapMap ref="mapRef"
                                         :center="{ lat:48.358492, lng:10.7914007 }"
                                         :zoom="12">
                                </GmapMap>
                            </div>

                            <div class="col-12 btn-group">
                                <button class="btn btn-primary btn-block btn-margin" v-on:click="closeRoute(item)">
                                    Mitfahrt abschließen und bewerten
                                </button>
                            </div>
                        </div>
                    </div>

                    <div v-else>
                        <div class="result-list text-left row">
                            <div class="result col-12 success-window">
                                <div class="box text-center">
                                    <div class="row">
                                        <div class="col-12">
                                            <b-icon icon="check"></b-icon>
                                            <br />
                                            <h2>Mitfahrt abgeschlossen!</h2>
                                            <br />
                                            <p>Erst nach der Bewertung ist eine erneute Mitfahrt möglich.</p>
                                            <br />

                                            <router-link :to="{ name: 'voteroute', params: { type: 'passenger', routeId: this.voteId } }">
                                                <button class="btn btn-block btn-primary btn-success">
                                                    Mitfahrt bewerten
                                                </button>
                                            </router-link>
                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-overlay>
</template>

<script>
    import bring2API from "@/api";
    import ResultBox from "@/components/snippets/ResultBox";

    export default {
        name: "BookedRoute",
        components: {
            ResultBox
        },

        data() {
            return {
                isError: false,
                errors: [],
                routeId: null,
                item: null,
                showLoading: true,
                currentLocation: null,
                markers: [],
                vm: null,
                map: null,
                booked: [],
                myStopLocation: null,
                detail: false,
                voteId: null,
            }
        },
        created() {
            if (this.$route.params.routeId > 0) {
                this.routeId = this.$route.params.routeId;
                bring2API.getBookedRoutes(this.routeId, this);
            } else {
                this.showLoading = false;
                this.isError = true;
                this.errors.push('Es ist ein Fehler aufgetreten!');
            }
        },

        mounted () {
            this.$refs.mapRef.$mapPromise.then((map) => {
                this.vm = this;
                this.map = map;

                // set directory service
                this.directionsService = new window.google.maps.DirectionsService();
                this.icon = {
                    path: "M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0",
                    fillColor: '#FF0000',
                    fillOpacity: 1,
                    anchor: new window.google.maps.Point(0,0),
                    strokeWeight: 0,
                    scale: 0.5
                };

                try {
                    this.currentLocation = new window.google.maps.LatLng(
                        this.$store.getters.getCurrentLocation.coords.latitude,
                        this.$store.getters.getCurrentLocation.coords.longitude
                    );

                    this.setMarker({ lat: this.currentLocation.lat(), lng: this.currentLocation.lng() }, 'Mein Standort');
                } catch  {
                    console.error("cant get current location");
                }
            });
        },

        methods: {
            setDirections(route, index) {
                var rendererOptions = {
                    // preserveViewport: true,
                    // suppressMarkers: true,
                    routeIndex: index,
                    polylineOptions: {
                        strokeColor: 'lilac'
                    }
                };
                var me = this;

                var directionsDisplay = new window.google.maps.DirectionsRenderer(rendererOptions);
                directionsDisplay.setMap(this.map);

                var waypoints = [];
                if(typeof(route.routeStopLocations) !== 'undefined') {
                    for (var i = 0; i < route.routeStopLocations.length; i++) {
                        waypoints[i] = {
                            location: route.routeStopLocations[i].location,
                            stopover: true
                        }
                    }
                }

                this.vm.directionsService.route({
                    origin: route.startLocation,
                    waypoints: waypoints,
                    destination: route.stopLocation,
                    travelMode: 'DRIVING'
                }, function (response, status) {
                    if (status === 'OK') {
                        var legs = response.routes[0].legs;
                        var startAddress = legs[0].start_address.split(',');
                        var endAddress = legs[legs.length-1].end_address.split(',');
                        var stopLocations = [];

                        var c = 0;
                        for(var stopLocation of route.routeStopLocations) {
                            var stopAddress = legs[c+1].start_address.split(',');

                            stopLocations.push({
                                id: stopLocation.id,
                                startTime: stopLocation.stopTime,
                                street: stopAddress[0],
                                city: stopAddress[1],
                                coords: stopLocation.location,
                            });

                            c++;
                        }

                        var item = {
                            id: route.id,
                            index: index,
                            distance: route.distance,
                            duration: route.duration,
                            startTime: route.startTime,
                            user: route.user,
                            origin: {
                                street: startAddress[0],
                                city: startAddress[1]
                            },
                            destination: {
                                street: endAddress[0],
                                city: endAddress[1]
                            },
                            stopLocations: stopLocations,
                            selected: '',
                            error: false,
                            errorMessage: '',
                            booked: false,
                            phone: 'tel:'+ (route.user.phone == null ? '' : route.user.phone),
                        };

                        me.item = me.computeBooked(item);
                        me.setMarker(me.item.booked.coords, 'Zustiegstelle');

                        console.log(me.item);

                        directionsDisplay.setDirections(response);
                    } else {
                        console.error('Directions request failed due to ' + status)
                    }
                });
            },

            setMarker(coords, title) {
                var marker = new window.google.maps.Marker({
                    position: coords,
                    map: this.map,
                });

                var infowindow = new window.google.maps.InfoWindow({
                    content: title
                });

                infowindow.open(this.map, marker);
            },

            computeBooked(item) {
                var me = this;

                item.booked = false;

                var bookedValue  = me.booked.filter(d => d.routeId === item.id);
                if (bookedValue.length > 0 && item.booked === false) {
                    if (bookedValue[0].stopRouteId == null) {
                        item.booked = item.origin;
                        item.booked.startTime = item.startTime;
                        item.booked.bookedId = bookedValue[0].id;
                        item.booked.stopDateTime = bookedValue[0].stopDateTime;
                    } else {
                        var stopBooked = item.stopLocations.filter(s => s.id === bookedValue[0].stopRouteId);
                        if (stopBooked.length > 0 && item.booked === false) {
                            item.booked = stopBooked[0];
                            item.booked.bookedId = bookedValue[0].id;
                            item.booked.stopDateTime = bookedValue[0].stopDateTime;
                        }
                    }
                }

                return item;
            },

            closeRoute(item) {
                if (item.booked.bookedId < 1) {
                    this.isError = true;
                    this.errors.push('Das abschließen der Mitfahrt hat nicht funktioniert, bitte laden Sie die Seite neu.');

                    return;
                }

                this.showLoading = true;

                fetch(this.$url + '/api/route-booked', {
                    method: 'PUT',
                    cache: 'no-cache',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({
                        'id': item.booked.bookedId,
                        'active': false
                    })
                })
                .then(response => response.json())
                .then(data => {
                    if (data.routeId === this.routeId) {
                        this.errors = [];
                        this.isError = false;
                        this.detail = true;
                        this.voteId = data.id;

                    } else {
                        this.isError = true;
                        this.errors.push('Das abschließen der Mitfahrt hat nicht funktioniert, bitte versuchen Sie es erneut.');
                    }

                    this.showLoading = false;
                })
                .catch(data => {
                    this.isError = true;
                    this.errors.push(data);

                    this.showLoading = false;
                })
            },

            deleteRoute(item) {
                bring2API.deleteRoute(item, this);
            },
        }
    }
</script>
