<template>
    <div class="content">
        <div class="row">
            <div class="col-12 text-left">
                <h1>Impressum</h1>

                <div class="card mb-1">
                    <div class="col-md-12">
                        <p>Angaben gemäß § 5 TMG:</p>
                        <p>
                            <strong>Stefan Haas</strong><br />
                            Lohberg 8<br />
                            Bonstetten<br />
                        </p>
                        <p>
                            E-Mail: <a href="mailto:kontakt@bring2.de">kontakt@bring2.de</a><br />
                            Web: <a href="https://www.bring2.de">www.bring2.de</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Imprint"
    }
</script>