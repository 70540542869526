export default {
    getRoutes(vm, me) {
        fetch(me.$url + '/api/route/?distance=' + me.circleDistance + '&lat=' + me.map.center.lat() + '&lng=' + me.map.center.lng() + '&cb=' + new Date().getTime(), {
            method: 'GET',
            cache: 'no-cache',
            headers: {'Content-Type': 'application/json'},
        })
            .then(response => response.json())
            .then(data => {
                data.forEach((value, index) => {
                    me.setDirections(value[0], vm, index);
                });
                me.showLoading = false;
            })
            .catch(data => {
                console.error(data);
                me.showLoading = false;
            })
    },

    getBookedRoutes(id, me) {
        fetch(me.$url + '/api/route-booked?cb='+ new Date().getTime(), {
            method: 'GET',
            cache: 'no-cache',
            headers: {'Content-Type': 'application/json'},
        })
        .then(response => response.json())
        .then(data => {
            me.booked = data;
            this.getRoute(id, me);
        })
        .catch(data => {
            console.error(data);
        })
    },

    getBookedRoute(id, me) {
        fetch(me.$url + '/api/route-booked/'+ id +'?cb='+ new Date().getTime(), {
            method: 'GET',
            cache: 'no-cache',
            headers: {'Content-Type': 'application/json'},
        })
        .then(response => response.json())
        .then(data => {
            me.getRoute(data);
        })
        .catch(data => {
            console.error(data);
        })
    },

    getRoute(id, me) {
        return fetch(me.$url + '/api/route/' + id + '?cb='+ new Date().getTime(), {
            method: 'GET',
            cache: 'no-cache',
            headers: {'Content-Type': 'application/json'},
        })
        .then(response => response.json())
        .then(data => {
            if (data.id === id) {
                me.setDirections(data, 1);
            } else {
                me.isError = true;
                me.errors.push(me.$msg.error["route-not-found"]);
                window.scrollTo(0,0);
            }

            me.showLoading = false;
        })
        .catch(data => {
            me.showLoading = false;
            me.isError = true;
            if (typeof(me.$msg.error[data.error]) != 'undefined') {
                me.errors.push(me.$msg.error[data.error]);
            } else {
                me.errors.push(me.$msg.error['unknown']);
            }
        })
    },

    deleteRoute(item, me) {
        var id = item.booked.bookedId;
        me.showLoading = true;
        me.isError = false;

        fetch(me.$url + '/api/route-booked/'+ id, {
            method: 'DELETE',
            cache: 'no-cache',
            headers: {'Content-Type': 'application/json'}
        })
        .then(response => response.json())
        .then(data => {
            if (data.success === true) {
                item.active = false;
                item.booked = false;
                me.isSuccess = false;
            } else {
                me.isError = true;

                if (typeof(me.$msg.error[data.error]) != 'undefined') {
                    me.errors.push(me.$msg.error[data.error]);
                } else {
                    me.errors.push(me.$msg.error['unknown']);
                }
            }

            me.showLoading = false;
        })
        .catch((data) => {
            me.showLoading = false;
            me.isError = true;

            if (typeof(me.$msg.error[data.error]) != 'undefined') {
                me.errors.push(me.$msg.error[data.error]);
            } else {
                me.errors.push(me.$msg.error['unknown']);
            }

            console.error("error on delete");
        })
    },

    deleteDriverRoute(item, me) {
        var id = item.id;
        me.showLoading = true;
        me.isError = false;

        fetch(me.$url + '/api/route/'+ id, {
            method: 'DELETE',
            cache: 'no-cache',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                'id': id,
                'active': false,
            })
        })
        .then(response => response.json())
        .then(data => {
            if (data.success === true) {
                item.active = false;
                me.isSuccess = false;
            } else {
                me.isError = true;

                if (typeof(me.$msg.error[data.error]) != 'undefined') {
                    me.errors.push(me.$msg.error[data.error]);
                } else {
                    me.errors.push(me.$msg.error['unknown']);
                }
            }

            me.showLoading = false;
        })
        .catch((data) => {
            me.showLoading = false;
            me.isError = true;

            if (typeof(me.$msg.error[data.error]) != 'undefined') {
                me.errors.push(me.$msg.error[data.error]);
            } else {
                me.errors.push(me.$msg.error['unknown']);
            }

            console.error("error on delete");
        })
    },
}